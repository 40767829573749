// Scripts for the support navigation component
import '../../../../styles/src/components/navigation/support.scss';

// Control scroll class to navigation
if( document.querySelector('.navigation') ) {
  window.addEventListener('scroll', () => {
    let navigation = document.querySelector('.navigation');
    if( document.documentElement.scrollTop > 300 ){
      navigation.classList.add('navigation--has-scrolled');
    } else {
      navigation.classList.remove('navigation--has-scrolled')
    }
  });
}
